import "./index.scss";
import Context from "../../Context";
import { useContext} from "react";
import { Link } from "react-router-dom";
import { numbersOnly } from "../../Utils";

const Footer = () => {
    const { resumo } = useContext(Context);

    return (
        <div className="footer-component bg-primary-contrast">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <Link to="/" className="logo mb-5">
                            <img src={`${localStorage.getItem('apiUrl')}imagens/large/logo_footer.svg`} alt="Logo" />
                        </Link>
                        <div className="socials d-flex mb-5">
                            {resumo.configuracoes && resumo.configuracoes.facebook &&
                                <a href={resumo.configuracoes.facebook} target="_blank" rel="noopener noreferrer" title="Facebook">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/large/facebook.svg`} alt="Facebook" />
                                </a>
                            }
                            {resumo.configuracoes && resumo.configuracoes.instagram &&
                                <a href={resumo.configuracoes.instagram} target="_blank" rel="noopener noreferrer" title="Instagram">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/large/instagram.svg`} alt="Instagram" />
                                </a>
                            }
                            {resumo.configuracoes && resumo.configuracoes.linkedin &&
                                <a href={resumo.configuracoes.linkedin} target="_blank" rel="noopener noreferrer" title="LinkedIn">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/large/linkedin.svg`} alt="LinkedIn" />
                                </a>
                            }
                            {resumo.configuracoes && resumo.configuracoes.youtube &&
                                <a href={resumo.configuracoes.youtube} target="_blank" rel="noopener noreferrer" title="YouTube">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/large/youtube.svg`} alt="YouTube" />
                                </a>
                            }
                        </div>
                    </div>
                    <div className="col-md-2 col-md-offset-1">
                        <Link className="p2 txt-white mb-2" to={"/"} title="Home">Home</Link>
                        <Link className="p2 txt-white mb-2" to={"/quem-somos"} title="Sobre nós">Sobre nós</Link>
                        <Link className="p2 txt-white mb-2" to={"/equipamentos"} title="Equipamentos">Equipamentos</Link>
                        {resumo.posts && resumo.posts.length > 0 && <Link className="p2 txt-white mb-2" to={"/blog"} title="Blog">Blog</Link>}
                    </div>
                    <div className="col-md-3">
                        <Link className="p2 txt-white mb-2" to={"/fale-conosco"} title="Fale conosco">Fale conosco</Link>
                        <Link className="p2 txt-white mb-2" to={"/trabalhe-conosco"} title="Trabalhe conosco">Trabalhe conosco</Link>
                        <Link className="p2 txt-white mb-2" to={"/politica-de-privacidade"} title="Política de privacidade">Política de privacidade</Link>
                        <Link className="p2 txt-white mb-2" to={"/termos-de-uso"} title="Termos de uso">Termos de uso</Link>
                    </div>
                    <div className="col-md-3">
                        <h4 className="txt-white">Contato</h4>
                        <p className="p2 txt-white"><span>Telefone: </span>{resumo.configuracoes.telefone}</p>
                        <p className="p2 txt-white"><span>WhatsApp: </span><a className="txt-white" href={`https://wa.me/55${numbersOnly(resumo.configuracoes.whatsapp)}`} target="_blank" rel="noopener noreferrer">{resumo.configuracoes.whatsapp}</a></p>
                        <p className="p2 txt-white"><span>E-mail: </span><a className="txt-white" href={`mailto:${resumo.configuracoes.email}`} target="_blank" rel="noopener noreferrer">{resumo.configuracoes.email}</a></p>
                    </div>
                </div>
                <div className="row top-divider">
                    <div className="col-md-9">
                        <p className="p2 txt-white">Siltomac - CNPJ: {resumo.configuracoes.cnpj} - Todos os direitos reservados © {new Date().getFullYear()}</p>
                    </div>
                    <div className="col-md-3">
                        <a className="p2 txt-right txt-white" href={"https://fw2propaganda.com.br"} target={"_blank"} title="Desenvolvido por Gustavo Barbosa" rel="noopener noreferrer">FW2 Propaganda</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;