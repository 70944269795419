import "./index.scss";
import Context from "../../Context";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
    const { resumo } = useContext(Context);
    const [menu, setMenu] = useState(false);

    return (
        <>
            <div className="header-component bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-center justify-space-between">
                            <div className="top d-flex align-center gap-15">
                                <Link to="/" className="logo border-primary-contrast">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/logo.svg`} alt="logo" />
                                </Link>
                                <div className={`menu d-flex gap-5 ${menu ? "active" : ""}`}>
                                    <Link className="btn bg-primary p2 txt-primary-contrast" to={"/"}>Home</Link>
                                    <Link className="btn bg-primary p2 txt-primary-contrast" to={"/quem-somos"}>Sobre nós</Link>
                                    <Link className="btn bg-primary p2 txt-primary-contrast" to={"/equipamentos"}>Equipamentos</Link>
                                    {resumo.posts && resumo.posts.length > 0 && <Link className="btn bg-primary p2 txt-primary-contrast" to={"/blog"}>Blog</Link>}
                                </div>
                            </div>
                            <div>
                                <Link className="d-none d-md-flex btn txt-primary bg-primary-contrast border-1" to={"/encontre-seu-equipamento"}>Encontre seu equipamento</Link>
                            </div>
                            <div className={`menu-controller d-flex d-md-none ${menu ? "active" : ""}`}>
                                <button onClick={() => setMenu(!menu)} className="btn bg-primary-shade border-0">
                                    <img src={`${localStorage.getItem('apiUrl')}imagens/thumb/${!menu ? "menu" : "close"}.svg`} alt="Menu" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </>
    )
}

export default Header;